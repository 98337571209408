import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import Analytics from '@/views/Analytics.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'DashboardLayout',
    component: DashboardLayout,
    redirect: { name: 'Occupancy Summary' },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/analytics',
        name: 'Analytics',
        component: Analytics,
      },
      {
        path: '/status',
        name: 'Occupancy Summary',
        component: () => import('@/views/Status.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    component: () => import('../views/NewPassword.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
const isAuthenticated = () => {
  const token = localStorage.getItem('idToken');
  return token ? true : false;
};

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) next('/login');
  else next();
});

export default router;
