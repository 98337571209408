type EmptyObject = {
  [key: string]: unknown;
};

export function formatTime(a: number): EmptyObject {
  const hours = Math.trunc(a / 60);
  const minutes = a % 60;
  const averageTime = { hours, minutes };
  return averageTime;
}

export const formatDate = (date: string): string => {
  if (!date) {
    return '';
  }
  const day = new Date(date).getDate();
  const month = new Date(date)?.toLocaleString('default', { month: 'short' });
  const year = new Date(date)?.getFullYear();
  // Truncate the seconds away from the time  so"1:19:40 PM" >> "1:19 PM"
  const time = new Date(date)?.toLocaleTimeString()?.replace(/(.*)\D\d+/, '$1');

  return `${day} ${month}, ${year}. ${time}`;
};

export function secondsToHmSs(time: number): string {
  if (!time) {
    return '0:00';
  }
  time = Number(time);
  const h = Math.floor(time / 3600);
  const m = Math.floor((time % 3600) / 60);
  const s = Math.floor(time % 60);

  const hDisplay = h > 0 ? h + ' hr' : '';
  const mDisplay = m > 0 ? m + ' min' : '';
  const sDisplay = s > 0 ? s + ' sec' : '';
  return `${hDisplay} ${mDisplay}  ${!hDisplay && !mDisplay ? sDisplay : ''} `;
}

export function generate24HTime(): string[] {
  const time = [];
  for (let i = 0; i < 24; i++) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    time.push(i < 10 ? `0${i}:00` : `${i}:00`);
  }
  return time;
}

export function getDateRange(
  startDate: string,
  endDate: string,
  steps = 1
): Date[] {
  const dateArray: Date[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(new Date(currentDate));
    // Use UTC date to prevent problems with time zones and DST
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }

  return dateArray;
}

export function convertToYyMmDd(d: string): string {
  const date = new Date(d);
  const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
  return dateString;
}
