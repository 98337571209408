import router from '@/router';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { checkTokenExpiration, refreshExpiredToken } from './aws-cognito.service';

const axiosApiInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 20000,
});

axiosApiInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      config.headers = config.headers ?? {};
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      (error?.response?.status === 403 || error?.response?.status === 401) &&
      (router.currentRoute.value.meta.requiresAuth ||
        !checkTokenExpiration(
          localStorage.accessToken,
          localStorage.idToken,
          localStorage.refreshToken
        )) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const accessToken = await refreshExpiredToken();
     ( ((axiosApiInstance as AxiosInstance).defaults as AxiosRequestConfig).headers as Record<string, string>).common[
        'Authorization'
      ] = `Bearer ${accessToken}`;

      return axiosApiInstance(originalRequest);
    }

    return Promise.reject(error);
  }
);

export { axiosApiInstance };
