/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const countSameNumber = (ar: any[], findNumber: number): number => {
  let count = 0;
  ar.map((value: any) => {
    if (value === findNumber) {
      count = count + 1;
    }
  });
  return count;
};
