
import { defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  ViewGridIcon,
  // CogIcon,
} from '@heroicons/vue/outline';
import { SearchIcon, PlusCircleIcon } from '@heroicons/vue/solid';
import StatusSvg from '@/assets/img/status.svg';
import Analytics from '@/assets/img/analytics.svg';

const navigation = [
  { name: 'Status', href: '/status', icon: StatusSvg },
  { name: 'Analytics', href: '/analytics', icon: Analytics },
  // { name: 'Settings', href: '/new-password', icon: CogIcon },
];
const userNavigation = [
  // { name: 'Your Profile', href: '/login' },
  { name: 'Change Password', href: '/new-password' },
  { name: 'Sign out', href: '/login' },
];
export default defineComponent({
  name: 'DashboardLayout',
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    // BellIcon,
    MenuAlt2Icon,
    // SearchIcon,
    XIcon,
    // PlusCircleIcon,
  },
  setup() {
    const sidebarOpen = ref(false);
    const routeName = ref();
    const route = useRoute();
    const router = useRouter();

    const handleUserNavigation = (item: string) => {
      if (item === '/login') {
        router.replace(item);
        localStorage.clear();
      } else {
        router.push(item);
      }
    };

    watch(
      () => route.name,
      () => {
        routeName.value = route?.name;
      }
    );
    return {
      navigation,
      userNavigation,
      sidebarOpen,
      routeName,
      handleUserNavigation,
    };
  },
});
