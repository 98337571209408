
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';
import { Chart, ChartConfiguration, ChartItem, registerables } from 'chart.js';
import { countSameNumber } from '@/utils/count';

export default defineComponent({
  name: 'ChartStacked',
  props: {
    sameDuration: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    vehicleChargeData: {
      type: Number,
      required: true,
      default: 0,
    },
    dateRange: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    dateSearchRange: {
      type: [Array, String],
      required: true,
      default: () => [],
    },
    chargedVehicles: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    occupiedVehicles: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    bayHogging: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    icingVehicles: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    analytics: {
      type: Array,
      required: true,
      default: () => [],
    },
    vehicleChargingSamedayResult: {
      type: Array,
      required: true,
      default: () => [],
    },
    vehicleOccupiedSamedayResult: {
      type: Array,
      required: true,
      default: () => [],
    },
    icingIncidentSamedayResult: {
      type: Array,
      required: true,
      default: () => [],
    },
    bayHoggingSamedayResult: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const totalChargedVehicles = ref();
    const windowWidth = ref(0);

    Chart.register(...registerables);
    const chartDataConfig = {
      type: 'bar',
      data: {
        labels: props.dateSearchRange,
        datasets: [
          {
            label: 'Charged',
            data: [0, 0, 0, 0, 0, 0, 0],
            backgroundColor: '#10B981',
            hoverBackgroundColor: '#9effcd',
          },
          {
            label: 'Occupied',
            data: [0, 0, 0, 0, 0, 0, 0],
            backgroundColor: '#1E40AF',
            hoverBackgroundColor: '#DBEAFE',
          },
          {
            label: 'Iceing',
            data: [0, 0, 0, 0, 0, 0, 0],
            backgroundColor: '#F59E0B',
            hoverBackgroundColor: '#ffeb99',
          },
          {
            label: 'Bay Hogging',
            data: [0, 0, 0, 0, 0, 0, 0],
            backgroundColor: '#991B1B',
            hoverBackgroundColor: '#ffb1b1',
          },
        ],
      },
      options: {
        responsive: true,
        // maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              font: {
                size: 14,
                family: 'Inter',
              },
            },
          },
        },
        barThickness: 60,
        ticks: {
          font: {
            family: 'Inter',
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            ticks: {
              stepSize: 1,
            },
            grid: {
              display: false,
            },
          },
        },
      },
    };
    let chart = null as Chart | null;

    function initChart() {
      const ctx = document.getElementById('chart-stacked') as HTMLCanvasElement;
      let chartStatus = Chart.getChart(ctx);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }
      chart = new Chart(
        ctx as ChartItem,
        chartDataConfig as ChartConfiguration
      );
    }

    watch(
      () => props.dateSearchRange,
      () => {
        chart?.destroy();

        chartDataConfig.data.labels = props.dateSearchRange;
        let totalCharge = [];
        let totalIcing = [];
        let totalBayHog = [];
        let totalOccupied = [];

        for (let key of Object.keys(props.chargedVehicles)) {
          // @ts-ignore
          totalCharge.push(props.chargedVehicles[key]);
        }
        chartDataConfig.data.datasets[0].data = totalCharge;
        // sum total array
        const sumCharge = totalCharge.reduce((a, b) => a + b, 0);

        totalChargedVehicles.value = sumCharge;

        for (let key of Object.keys(props.occupiedVehicles)) {
          // @ts-ignore
          totalOccupied.push(props.occupiedVehicles[key]);
        }
        // Update occupied vehicle incidents dataset
        chartDataConfig.data.datasets[1].data = totalOccupied;

        for (let key of Object.keys(props.icingVehicles)) {
          // @ts-ignore
          totalIcing.push(props.icingVehicles[key]);
        }
        // Update Icing Incidents dataset
        chartDataConfig.data.datasets[2].data = totalIcing;

        for (let key of Object.keys(props.bayHogging)) {
          // @ts-ignore
          totalBayHog.push(props.bayHogging[key]);
        }
        // Update Icing Incidents dataset
        chartDataConfig.data.datasets[3].data = totalBayHog;

        initChart();
        // chart?.update();
      }
    );

    watch(
      () => props.sameDuration,
      () => {
        // @ts-ignore
        chartDataConfig.data.labels = props.sameDuration;
        // Update Charged Vehicles dataset
        chartDataConfig.data.datasets[0].data = [0];
        let vehicleChargedSet = new Set(props.vehicleChargingSamedayResult);

        for (let entry of vehicleChargedSet) {
          // @ts-ignore
          chartDataConfig.data.datasets[0].data[entry] = countSameNumber(
            props.vehicleChargingSamedayResult,
            entry as number
          );
        }

        // Update Occupied Incident dataset
        chartDataConfig.data.datasets[1].data = [0];
        let vehicleOccupied = new Set(props.vehicleOccupiedSamedayResult);

        for (let entry of vehicleOccupied) {
          // @ts-ignore
          chartDataConfig.data.datasets[1].data[entry] = countSameNumber(
            props.vehicleOccupiedSamedayResult,
            entry as number
          );
        }

        // Update Icing Incident dataset
        chartDataConfig.data.datasets[2].data = [0];
        let icingIncidentSet = new Set(props.icingIncidentSamedayResult);

        for (let entry of icingIncidentSet) {
          // @ts-ignore
          chartDataConfig.data.datasets[2].data[entry] = countSameNumber(
            props.icingIncidentSamedayResult,
            entry as number
          );
        }

        // Update bay hogging Incident dataset
        chartDataConfig.data.datasets[3].data = [0];
        let bayHoggingIncidentSet = new Set(props.bayHoggingSamedayResult);
        for (let entry of bayHoggingIncidentSet) {
          // @ts-ignore
          chartDataConfig.data.datasets[3].data[entry] = countSameNumber(
            props.bayHoggingSamedayResult,
            entry as number
          );
        }

        chart?.destroy();
        initChart();
        chart?.update();
      }
    );
    function onResize() {
      windowWidth.value = window.innerWidth;
    }
    onMounted(async () => {
      await initChart();
      windowWidth.value = window.innerWidth;

      nextTick(() => {
        window.addEventListener('resize', onResize);
      });
      if (windowWidth.value < 768) {
        // @ts-ignore
        chartDataConfig.options.maintainAspectRatio = false;
      }
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize);
    });
    onBeforeUnmount(() => {
      chart?.destroy();
    });
    onBeforeMount(() => {
      chart?.destroy();
    });
    return { totalChargedVehicles };
  },
});
