import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import router from '@/router';
import config from '@/aws-exports';

export const awsConfig = () => {
  if (typeof config !== 'object' || Array.isArray(config)) {
    throw new Error('[CognitoAuth error] valid option object required');
  }

  const userPool = new CognitoUserPool({
    UserPoolId: config.identityPoolId as string,
    ClientId: config.userPoolWebClientId as string,
  });

  // Config.region = config.region
  // const provider = new CognitoIdentityProvider({ region: config.region })

  // Config.credentials = new CognitoIdentityCredentials({
  // 	IdentityPoolId: config.identityPoolId as string,
  // })
  return userPool;
};

function cognitoFlowType(cognitoUser: CognitoUser) {
  return cognitoUser.setAuthenticationFlowType(
    config.authenticationFlowType as string
  );
}

export function login(
  username: string,
  pass: string,
  cb: (err: any, result: any) => void,
  newPassword?: string
) {
  let sessionUserAttributes;
  const authenticationData = {
    Username: username,
    Password: pass,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const userData = {
    Username: username,
    Pool: awsConfig(),
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoFlowType(cognitoUser);

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function (result) {
      // let accessToken = result.getAccessToken().getJwtToken();
      /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
      // let idToken = result.idToken.jwtToken;
      cb(null, result);
    },

    onFailure: function (err) {
      cb(err.message || JSON.stringify(err), null);
    },
    newPasswordRequired: function (userAttributes) {
      // User was signed up by an admin and must provide new
      // password and required attributes, if any, to complete
      // authentication.

      // the api doesn't accept this field back
      delete userAttributes.email_verified;
      // store userAttributes on global variable
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      sessionUserAttributes = userAttributes;

      cognitoUser.completeNewPasswordChallenge(
        newPassword as string,
        {},
        {
          onSuccess: (user) => {
            console.log('success', user);
          },
          onFailure: (error) => {
            console.log(error);
          },
        }
      );
    },
  });
}

export function forgotPassword(username, cb) {
  let userData = {
    Username: username,
    Pool: awsConfig(),
  };

  let cognitoUser = new CognitoUser(userData);

  cognitoUser.setAuthenticationFlowType(config.authenticationFlowType);

  cognitoUser.forgotPassword({
    onSuccess: function (result) {
      // let accessToken = result.getAccessToken().getJwtToken();
      /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
      // let idToken = result.idToken.jwtToken;
      cb(null, result);
    },

    onFailure: function (err) {
      alert(err);
      cb(err);
    },
  });
}
export function confirmPassword(username, verificationCode, newPassword, cb) {
  let userData = {
    Username: username,
    Pool: awsConfig(),
  };

  let cognitoUser = new CognitoUser(userData);

  cognitoUser.setAuthenticationFlowType(config.authenticationFlowType);

  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onSuccess: function (result) {
      // let accessToken = result.getAccessToken().getJwtToken();
      /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
      // let idToken = result.idToken.jwtToken;
      cb(null, result);
    },

    onFailure: function (err) {
      cb(err || JSON.stringify(err), null);
    },
  });
}

export function newPassword(oldPassword, newPassword, cb) {
  return new Promise((resolve, reject) => {
    const currentUser = getCurrentUser();
    if (currentUser) {
      currentUser.getSession(function (err) {
        if (err) {
          reject(err);
        } else {
          currentUser.changePassword(
            oldPassword,
            newPassword,
            function (err, res) {
              if (err) {
                cb(err.message || JSON.stringify(err));
                return;
              }
              cb(res);
            }
          );
        }
      });
    } else {
      cb('User is not authenticated');
      reject('User is not authenticated');
    }
  });
}

export function getCurrentUser() {
  const userPool = new CognitoUserPool({
    // Username: username,
    UserPoolId: config.identityPoolId as string,
    ClientId: config.userPoolWebClientId as string,
  });

  return userPool.getCurrentUser();
}

export async function checkTokenExpiration(
  accessToken: string,
  idToken: string,
  refreshToken: string
) {
  const AccessToken = new CognitoAccessToken({
    AccessToken: accessToken,
  });

  const IdToken = new CognitoIdToken({ IdToken: idToken });

  const RefreshToken = new CognitoRefreshToken({
    RefreshToken: refreshToken,
  });

  const sessionData = {
    IdToken,
    AccessToken,
    RefreshToken,
  };

  const cachedSession = new CognitoUserSession(sessionData);

  if (cachedSession.isValid()) {
    return true;
  }
  return false;
}

export async function getTokens(username, refreshToken, cb) {
  let userData = {
    Username: username,
    Pool: awsConfig(),
  };

  let cognitoUser = new CognitoUser(userData);
  const RefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });

  cognitoUser.refreshSession(RefreshToken, async (err, result) => {
    if (err) throw new Error(err);
    await cb(result);
  });
}

export function refreshSession(username: string, refreshToken: string) {
  const userData = {
    Username: username,
    Pool: awsConfig(),
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(
      new CognitoRefreshToken({ RefreshToken: refreshToken }),
      async (err, data) => {
        if (err) {
          reject(err);
          router.replace({ name: 'Login' });
        } else {
          resolve(data);
        }
      }
    );
  });
}

export async function refreshExpiredToken(): Promise<void> {
  const tokens: any = await refreshSession(
    localStorage.username,
    localStorage.refreshToken
  );

  if (tokens) {
    const accessToken = tokens.getAccessToken().getJwtToken();
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('idToken', tokens.idToken.jwtToken);
    localStorage.setItem('refreshToken', tokens.refreshToken.token);
    return accessToken;
  }
}
