/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const auth = {
  namespaced: true,
  state: {
    user: null,
    accessToken: '',
    idToken: null,
    isAuthenticated: false,
    isLoading: false,
    error: null,
  },
  getters: {},
  mutations: {
    SET_TOKEN(state: any, { accessToken, idToken }): void {
      state.accessToken = accessToken;
      state.idToken = idToken;
    },
  },
  actions: {},
};
