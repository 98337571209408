
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  PropType,
  watch,
} from 'vue';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import useLoading from '@/use/useLoading';
import { countSameNumber } from '@/utils/count';

type Entry = {
  duration: number;
  occupiedStart: number;
};

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    statsOne: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    statsTwo: {
      type: String,
      required: true,
    },
    success: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    chartData: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    dateSearchRange: {
      type: [Array, String],
      required: false,
      default: () => [],
    },
    sameDuration: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    vehicleChargingSamedayResult: {
      type: Array,
      required: false,
      default: () => [],
    },
    vehicleOccupiedSamedayResult: {
      type: Array,
      required: false,
      default: () => [],
    },
    icingIncidentSamedayResult: {
      type: Array,
      required: false,
      default: () => [],
    },
    averageChargeSamedayResult: {
      type: Array as PropType<Entry[]>,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    Chart.register(...registerables);
    const { isLoading } = useLoading(true);

    const chartData = {
      type: 'line',
      data: {
        labels: [
          'Jan',
          'Feb',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
        ],

        datasets: [
          {
            lineTension: 0,
            label: 'Vehicle Charged',
            data: [0, 0, 0, 0, 0, 0, 0, 0],
            borderColor: '#4338CA',
            backgroundColor: '#4338CA',
            fill: false,
            borderWidth: 1,
            pointRadius: 2.6,
          },
        ],
      },
      options: {
        bezierCurve: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            usePointStyle: true,
            backgroundColor: '#fff',
            titleColor: '#000000',
            bodyColor: '#000000',
            shadowOffsetX: 3,
            shadowOffsetY: 3,
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            cornerRadius: 8,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
    };

    let chart = null as Chart | null;
    const arrZeroOf24Hours = Array.from({ length: 24 }).fill(0);

    function initChart() {
      const ctx = document.getElementById(props.id) as HTMLCanvasElement;

      let chartStatus = Chart.getChart(ctx);

      if (chartStatus != undefined) {
        chartStatus.destroy();
      }
      if (!isLoading.value) {
        chart = new Chart(ctx, chartData as ChartConfiguration);
      }
    }

    watch(
      () => props.dateSearchRange,
      () => {
        chart?.destroy();
        // @ts-ignore
        chartData.data.labels = props.dateSearchRange;
        if (props.id === 'vehicle') {
          let total = [];
          // @ts-ignore
          for (let key of Object.keys(props.chartData)) {
            // @ts-ignore
            total.push(props.chartData[key]);
          }
          chartData.data.datasets[0].data = total;
        }
        if (props.id === 'occupied') {
          setTimeout(() => {
            let total = [];
            // @ts-ignore
            for (let key of Object.keys(props.chartData)) {
              // @ts-ignore
              total.push(props.chartData[key]);
            }
            chartData.data.datasets[0].data = total;
            chartData.data.datasets[0].label = 'Occupied Bay';
            chart?.destroy();
            initChart();
          }, 10);
        }
        if (props.id === 'ice') {
          setTimeout(() => {
            let total = [];
            // @ts-ignore
            for (let key of Object.keys(props.chartData)) {
              // @ts-ignore
              total.push(props.chartData[key]);
            }
            chartData.data.datasets[0].data = total;
            chartData.data.datasets[0].label = 'Iceing Incidents';
            chart?.destroy();
            initChart();
            // if (chart) {
            //   chart?.update();
            // }
          }, 10);
        }
        if (props.id === 'hog') {
          setTimeout(() => {
            let total = [];
            // @ts-ignore
            for (let key of Object.keys(props.chartData)) {
              // @ts-ignore
              total.push(props.chartData[key]);
            }
            chartData.data.datasets[0].data = total;
            chartData.data.datasets[0].label = 'Bay Hogging Incidents';
            chart?.destroy();
            initChart();
            // if (chart) {
            //   chart?.update();
            // }
          }, 10);
        }
        if (props.id === 'time') {
          setTimeout(() => {
            let total = [];
            // @ts-ignore
            for (let key of Object.keys(props.chartData)) {
              // @ts-ignore
              total.push(Math.round(props.chartData[key]));
            }
            // let timeFormatted =  total.map((item) => `${Number(item)} minutes`)

            chartData.data.datasets[0].data = total;
            chartData.data.datasets[0].label = 'Total time charging (min)';
            chart?.destroy();
            initChart();
            // if (chart) {
            //   chart?.update();
            // }
          }, 100);
        }
        initChart();
        // if (chart) {
        //   chart?.update();
        // }
        isLoading.value = false;
      }
    );

    watch(
      () => props.sameDuration,
      () => {
        // @ts-ignore
        chartData.data.labels = props.sameDuration;
        if (props.id === 'vehicle') {
          // Update Charged Vehicles dataset
          // @ts-ignore
          chartData.data.datasets[0].data = arrZeroOf24Hours;
          let vehicleChargedSet = new Set(props.vehicleChargingSamedayResult);

          for (let entry of vehicleChargedSet) {
            // @ts-ignore
            chartData.data.datasets[0].data[entry] = countSameNumber(
              props.vehicleChargingSamedayResult,
              entry as number
            );
          }
        }
        if (props.id === 'occupied') {
          // Update occupied Vehicles dataset
          let vehicleOccupiedSet = new Set(props.vehicleOccupiedSamedayResult);
          setTimeout(() => {
            // @ts-ignore
            chartData.data.datasets[0].data = arrZeroOf24Hours;
            chartData.data.datasets[0].label = 'Occupied Bay';
            console.log(vehicleOccupiedSet);

            for (let entry of vehicleOccupiedSet) {
              // @ts-ignore
              chartData.data.datasets[0].data[entry] = countSameNumber(
                props.vehicleOccupiedSamedayResult,
                entry as number
              );
            }
            chart?.destroy();
            initChart();
          }, 10);
        }
        if (props.id === 'ice') {
          let icingIncidentSet = new Set(props.icingIncidentSamedayResult);
          setTimeout(() => {
            // @ts-ignore
            chartData.data.datasets[0].data = arrZeroOf24Hours;
            chartData.data.datasets[0].label = 'Bay Hogging Incidents';
            // Update Icing Incident dataset
            for (let entry of icingIncidentSet) {
              // @ts-ignore
              chartData.data.datasets[0].data[entry] = countSameNumber(
                props.icingIncidentSamedayResult,
                entry as number
              );
            }
            chart?.destroy();
            initChart();
            // if (chart) {
            //   chart?.update();
            // }
          }, 10);
        }
        if (props.id === 'hog') {
          let icingIncidentSet = new Set(props.icingIncidentSamedayResult);
          setTimeout(() => {
            // @ts-ignore
            chartData.data.datasets[0].data = arrZeroOf24Hours;
            chartData.data.datasets[0].label = 'Icing Incidents';
            // Update Icing Incident dataset
            for (let entry of icingIncidentSet) {
              // @ts-ignore
              chartData.data.datasets[0].data[entry] = countSameNumber(
                props.icingIncidentSamedayResult,
                entry as number
              );
            }
            chart?.destroy();
            initChart();
            // if (chart) {
            //   chart?.update();
            // }
          }, 10);
        }
        if (props.id === 'time') {
          setTimeout(() => {
            // let averageChargeSet = new Set(props.averageChargeSamedayResult);

            // @ts-ignore
            chartData.data.datasets[0].data = arrZeroOf24Hours;
            chartData.data.datasets[0].label = 'Total time charging (min)';

            const groupTotalHourChargingByDay = Array.from(
              props.averageChargeSamedayResult.reduce(
                (m, { duration, occupiedStart }) =>
                  m.set(occupiedStart, (m.get(occupiedStart) || 0) + duration),
                new Map()
              ),
              ([occupiedStart, duration]) => ({ occupiedStart, duration })
            );

            for (let entry of groupTotalHourChargingByDay) {
              chartData.data.datasets[0].data[entry.occupiedStart] =
                entry.duration;
            }
            chart?.destroy();
            initChart();
            // if (chart) {
            //   chart?.update();
            // }
          }, 10);
        }

        chart?.destroy();
        initChart();
        // if (chart) {
        //   chart?.update();
        // }
      }
    );

    watch(
      () => props.statsOne,
      () => {
        if (props.statsOne) {
          isLoading.value = false;
        }
      }
    );

    onMounted(() => {
      initChart();
    });

    onBeforeUnmount(() => {
      chart?.destroy();
    });
    onBeforeMount(() => {
      chart?.destroy();
    });
    return { isLoading };
  },
});
