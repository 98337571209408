import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { axiosApiInstance as axios } from './services/axios.config';
import './assets/css/tailwind.css';
import './assets/css/html.reset.css';
import { useVuelidate } from '@vuelidate/core';
import OtpInput from 'vue3-otp-input';

const app = createApp(App);

app.provide('$axios', axios);

app
  .use(store)
  .use(router)
  .use(useVuelidate)
  .component('v-otp-input', OtpInput)
  .mount('#app');
