/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse, AxiosStatic } from 'axios';
// import {
//   getTokens,
//   checkTokenExpiration,
// } from '@/services/aws-cognito.service';
// import { axiosApiInstance } from '@/services/axios.config';
import { Analytics, StationBay } from '@/types/interface';

export const getLocation = (http: AxiosStatic): Promise<any> => {
  return http.get('/location');
};
export const getAnalytics = (
  http: AxiosStatic,
  locationId: string,
  startDate?: string,
  endDate?: string
): Promise<AxiosResponse<Analytics[]>> => {
  return http.get('/analytics/usage', {
    params: {
      location: locationId,
      startDate,
      endDate,
    },
  });
};
export const getStatus = (
  http: AxiosStatic,
  locationId: string,
  startDate?: string,
  endDate?: string
): Promise<AxiosResponse<StationBay[]>> => {
  return http.get('/analytics/status', {
    params: {
      location: locationId,
      // startDate,
      // endDate,
    },
  });
};
