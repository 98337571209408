
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  defineComponent,
  onMounted,
  PropType,
  watch,
  ref,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import { Chart, ChartConfiguration, ChartItem, registerables } from 'chart.js';
import { Analytics } from '@/types/interface';

export default defineComponent({
  props: {
    dateSearchRange: {
      type: [Array, String],
      required: true,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
    },
    sameDuration: {
      type: [Array, String],
      required: true,
      default: () => [],
    },
    analytics: {
      type: Array as PropType<Analytics[]>,
      required: true,
      default: () => [],
    },
    selectedLocation: {
      type: Object as PropType<{ name: string; id: string }>,
      required: true,
      default: () => [],
    },
    vehicleChargeData: {
      type: Number,
      required: true,
      default: 0,
    },
    icingIncident: {
      type: [String],
      required: true,
      default: '0',
    },
  },
  setup(props) {
    Chart.register(...registerables);

    let chart = null as Chart | null;
    const chargingPercentage = ref(0);
    const iceingPercentage = ref(0);
    const bayHoggingPercent = ref(0);
    const windowWidth = ref(0);

    const chartData = ref({
      type: 'doughnut',
      data: {
        labels: [
          `Charging  ${chargingPercentage.value}%`,
          `Iceing ${iceingPercentage.value}%`,
          `Bay Hogging ${bayHoggingPercent.value}%`,
          `Occupied ${bayHoggingPercent.value}%`,
        ],
        datasets: [
          {
            data: [
              props.analytics.length,
              (Number(props.icingIncident) / props.analytics.length) * 100,
            ],
            backgroundColor: ['#10B981', '#F59E0B', '#991B1B', '#1E40AF'],
          },
        ],
      },
      options: {
        cutoutPercentage: 54,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem.label}`;
              },
            },
          },
          legend: {
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              font: {
                size: 14,
                family: 'Inter',
              },
            },
            position: 'bottom',
          },
        },
      },
    });
    const chartCtx = ref<HTMLCanvasElement | null>(null);
    function initChart() {
      chartCtx.value = document.getElementById(
        'doughnut-chart'
      ) as HTMLCanvasElement;
      chart = new Chart(chartCtx.value as ChartItem, chartData.value as ChartConfiguration);
    }

    watch(
      () => [props.dateSearchRange, props.sameDuration],
      () => {
        const charging: Analytics[] = [];
        const occupied: Analytics[] = [];
        const bayhogging: Analytics[] = [];
        const icingIncident: Analytics[] = [];

        props.analytics.forEach((item: Analytics) => {
          if (!item.occupiedEnd) {
            if (item.charging) {
              charging.push(item);
            } else {
              occupied.push(item);
            }
          } else {
            if (item.charging) {
              charging.push(item);
            }
            if (item.bayHoggingIncident) {
              bayhogging.push(item);
            }
            if (item.icingIncident) {
              icingIncident.push(item);
            }
            if (
              !item.bayHoggingIncident &&
              !item.icingIncident &&
              !item.charging
            ) {
              occupied.push(item);
            }
          }
        });

        const iceingPercentageCompute = () => {
          let num: number = 0;
          num = (Number(icingIncident) / props.analytics.length) * 100;
          num = Number(Math.round(num));
          return num;
        };

        const chargingPercentageCompute = (): number => {
          // let num = 0;
          // num =
          //   ((Number(
          //     props.analytics.filter(
          //       (chrg) => !chrg.bayHoggingIncident && !chrg.icingIncident
          //     ).length
          //   ) -
          //     Number(props.icingIncident)) /
          //     props.analytics.length) *
          //   100;
          // num = Number(Math.round(num));
          // return num as number;
          return Number(
            Math.round((charging.length / props.analytics.length) * 100)
          );
          // return Math.round(props.vehicleChargeData) / 100;
        };
        const occupiedPercentageCompute = (): number => {
          return Number(
            Math.round((occupied.length / props.analytics.length) * 100)
          );
          // return Math.round(props.vehicleChargeData) / 100;
        };
        const bayHoggingPercentCompute = (): number => {
          return Number(
            Math.round((bayhogging.length / props.analytics.length) * 100)
          );
        };

        chart?.destroy();
        chargingPercentage.value = chargingPercentageCompute();
        iceingPercentage.value = iceingPercentageCompute();
        bayHoggingPercent.value = bayHoggingPercentCompute();

        // update chart labels
        chartData.value.data.labels = [
          `Charging  ${chargingPercentageCompute()}%`,
          `Iceing ${iceingPercentageCompute()}%`,
          `Bay Hogging ${bayHoggingPercentCompute()}%`,
          `Occupied ${occupiedPercentageCompute()}%`,
        ];

        // update chart data
        chartData.value.data.datasets[0].data[0] = chargingPercentageCompute();
        chartData.value.data.datasets[0].data[1] = iceingPercentageCompute();
        chartData.value.data.datasets[0].data[2] = bayHoggingPercentCompute();
        chartData.value.data.datasets[0].data[3] = occupiedPercentageCompute();
        initChart();
        chart?.update();
      }
    );

    onMounted(() => {
      initChart();
      windowWidth.value = window.innerWidth;
      if (windowWidth.value > 1600) {
        // chartData.value.options.maintainAspectRatio = false;
        (chartCtx.value as HTMLCanvasElement).width = 900;
        (chartCtx.value as HTMLCanvasElement).height = 900

        chart?.update();

      }
    });
    onBeforeUnmount(() => {
      chart?.destroy();
    });
    onBeforeMount(() => {
      chart?.destroy();
    });
  },
});
